
$displayFont: 'Noto Sans Lao', serif;
$bodyFont: 'Noto Sans Lao', sans-serif;

body{
  background-color: $white;
}
.container {
  @include xy-grid-container();
}
.site-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.footer, .header {
  flex-shrink: 0;
}
.main {
  flex: 1 0 auto;
}
.color {
  color: $primary-color;
}
section.header {
  @include xy-grid-container(100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: $almost-black;
  border-bottom: 1px solid $medium-gray;
  @include flex();
  @include flex-align(center);
  .headerContainer {
    @include xy-cell(12);
    @include breakpoint(xlarge) {
      @include xy-cell(9);
    }
  }
  .homeHeaderContent {
    @include xy-grid();
    @include flex-align($x: null, $y: middle);
    padding-top: rem-calc(8);
      img.logo {
        margin-bottom: rem-calc(8);
        width: rem-calc(50);
        height: rem-calc(50);
      }
      h1 {
        @include xy-cell(auto);
        padding-top: rem-calc(4);
        text-transform: uppercase;
        font-size: rem-calc(15);
        font-family: $displayFont;
        color: $primary-color;
        font-weight: 700;
        a:hover {
          color: $medium-gray;
        }
        @include breakpoint(large) {
          font-size: rem-calc(15);
        }

        span.photography {
          color: $light-gray;
          font-size: rem-calc(10);
          vertical-align: middle;
          font-weight: 400;
          text-transform: uppercase;
          @include breakpoint(medium) {
            font-size: rem-calc(10);
          }
          @include breakpoint(large) {
            font-size: rem-calc(12);
          }
        }
      }
      ul.menu {
        @include xy-cell(shrink);
        @include flex-align($x: right);
        margin-right: rem-calc(0);
        li {
          a {
            font-family: $displayFont;
            font-weight: 500;
            color: $primary-color;
            text-transform: uppercase;
            font-size: rem-calc(12);
            letter-spacing: rem-calc(1);
            padding-left: rem-calc(14);
            padding-right: rem-calc(0);
            &:hover {
              color: $medium-gray;
            }
          }
        }
      }
    }
    button#A {
      color: $light-gray;
      @include breakpoint(large) {
        display: none;
      }
    }
    button#A::after {
      background: #dddddd;
      -webkit-box-shadow: 0 7px 0 #dddddd, 0 14px 0 #dddddd;
      box-shadow: 0 7px 0 #dddddd, 0 14px 0 #dddddd;
    }
    div.menuForSlide {
      @include xy-cell(12);
      display: none;
      width: 100%;
      padding: 10px;
      @media only screen and (orientation: landscape) {
        max-height: 70vh;
        overflow-y: auto;
      }
      ul {
        padding-top: rem-calc(20);
        list-style-type: none;
      }
      ul hr {
        height: rem-calc(1);
        background-color: $primary-color;
        border: none;
        max-width: 100%;
      }
      ul li {
        width: 100%;
        text-align: right;
        padding-bottom: rem-calc(12);
        line-height: 60%;
        p {
          color: $light-gray;
          text-transform: uppercase;
          font-size: rem-calc(10);
          letter-spacing: rem-calc(2);
          margin-bottom: rem-calc(4);
          margin-top: rem-calc(10);
        }
        ul.nested {
          padding-top: rem-calc(10);
          li a {
            @include text-transform(uppercase);
            font-weight: normal;
            letter-spacing: rem-calc(1);
            font-size: rem-calc(10);
            color: $medium-gray;
            &:hover {
              color: $primary-color;
            }
          }
        }

        a {
          font-family: $displayFont;
          color: $light-gray;
          text-transform: uppercase;
          font-size: rem-calc(10);
          letter-spacing: rem-calc(2);
          padding-right: rem-calc(0);
          &:hover {
            color: $primary-color;
          }
          span {
            font-size: rem-calc(8);
            letter-spacing: rem-calc(0);
            color: $medium-gray;
            font-family: $bodyFont;
          }
        }
      }
  }
}
footer.footer {
  padding-top: rem-calc(25);
  ul.contact {
    @include xy-grid();
    @include flex;
    @include flex-align(center);
    list-style-type: none;
    margin: rem-calc(0);

    a {
      img {
        width: rem-calc(24);
        height: rem-calc(24);
        @include breakpoint(large) {
          width: rem-calc(28);
          height: rem-calc(28);
        }
      }
    }
  }
  .copyright-credits {
    @include xy-grid();
  	padding-top: rem-calc(8);

    .copyrightContent {
      @include xy-cell(12);

      p.copyright {
        padding-top: rem-calc(6);
    		color: darken($white, 50);
    		font-size: rem-calc(10);
    	}
    	.fi-home {
    		font-size: rem-calc(18);
    		color: $light-gray;
    		padding: rem-calc(0 4 0 0);
    	}
    	.fi-home:hover {
    		color: darken($light-gray, 10);
    	}
    	p {
    		font-size: rem-calc(22);
    		text-align: center;
    		color: $light-gray;
    	}
    	@include breakpoint(medium) {
    		// text-align: left;
    		p {
    			// text-align: left;
    			font-size: rem-calc(24);
    		}
    		.fi-home {
    			font-size: rem-calc(22);
    		}
    	}
    }
  }
}
div.lg-sub-html {
  padding-top: rem-calc(40);
  h3 {
    font-size: rem-calc(11);
    margin: 0;
    font-family: $displayFont;
    text-transform: uppercase;
    font-weight: 800;
    color: $primary-color;
  }
  h4 {
    color: $medium-gray;
    font-size: rem-calc(10);
    font-weight: 500;
  }
  // color: $light-gray;
  // font-size: rem-calc(13);
  // .workTitle {
  //   font-family: $playfair;
  //   font-size: rem-calc(15);
  //   font-weight: bold;
  //   color: $primary-color;
  //   margin-right: rem-calc(4);
  // }
}
// .lg-inner {
//   padding-top: rem-calc(40);
//   margin-bottom: rem-calc(80);
// }
.galleryNav {
  @include xy-cell(shrink);
  @include flex();
  // @include flex-align(right);
  @include show-for(large);
  ul {
    padding-top: rem-calc(50);
    list-style-type: none;
    li.hasNested {
      padding-top: rem-calc(0);
      p.nestedMenuHeader {
        font-size: rem-calc(12);
        text-transform: uppercase;
        font-weight: bold;
        color: $secondary-color;
        margin-bottom: rem-calc(6);
      }
      ul.nested {
        padding-top: rem-calc(8);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        li {
          margin-bottom: rem-calc(12);
          a.navLink[aria-current="page"] {
            color: $primary-color;
            font-size: rem-calc(10);
            font-weight: bold;
          }
        }
        li a {
          @include text-transform(uppercase);
          font-weight: normal;
          letter-spacing: rem-calc(1);
          font-size: rem-calc(11);
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    li {
      margin-bottom: rem-calc(12);
      text-align: right;
      line-height: 70%;
      a {
        @include text-transform(uppercase);
        font-family: $displayFont;
        font-weight: 700;
        letter-spacing: rem-calc(1);
        font-size: rem-calc(11);
        color: $secondary-color;
        &:hover {
          color: $primary-color;
        }
        span {
          font-family: $bodyFont;
          font-weight: 600;
          font-size: rem-calc(10);
          letter-spacing: rem-calc(0);
        }
      }
      a.navLink[aria-current="page"] {
        color: $primary-color;
      }
      a.instagram {
        img {
          width: rem-calc(24);
          height: rem-calc(24);
        }
      }
    }
    li.otherLinks {
      margin-top: rem-calc(38);

      a {
        border-top: 2px solid $primary-color;
        padding-top: rem-calc(10);
      }
    }
  }
}
.moreAndReturn {
  @include xy-cell(12);
  @include flex();
  @include flex-align(center);
  border-top: 1px solid $medium-gray;
  padding-top: rem-calc(4);
  a {
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
    font-size: rem-calc(14);
    font-weight: bold;
  }
  .moreButton {
    @include breakpoint(large) {
      display: none;
    }
  }
}
section.homePage {
  @include xy-grid;
  @include flex;
  @include flex-align(center);
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(40);
  @include breakpoint(medium) {
    padding-top: rem-calc(90);
  }
  .pageTitle {
    @include xy-cell(12);
    @include breakpoint(small only) {
      display: none;
    }
    h4  {
      text-align: center;
      text-transform: uppercase;
      font-family: $displayFont;
      font-weight: 600;
      font-size: rem-calc(13);
      color: $primary-color;
      line-height: 90%;
      margin-bottom: rem-calc(4);
      }
    }
  .galleryContainer {
    @include xy-cell(12);
    @include flex;
    @include flex-align(center);
    .myIsGallery {
      margin: 0;
      padding-bottom: rem-calc(8);
      @include xy-cell(10);
      @include breakpoint(medium) {
        @include xy-cell(10);
      }
      @include breakpoint(large) {
        @include xy-cell(10);
      }
      @include breakpoint(xlarge) {
        @include xy-cell(9);
      }
      @include breakpoint(xxlarge) {
        @include xy-cell(8);
      }
      a.isGallery-large {
        width: 100%;
        border: 12px solid $white;
        @include breakpoint(medium) {
          width: 50%;
        }
        @include breakpoint(large) {
          width: 50%;
        }
        @include breakpoint(xxlarge) {
          width: 33.33%;
        }
        @include breakpoint(xxxxlarge) {
          width: 25%;
        }
        .collectionHeading {
          text-align: center;
          position: absolute;
          z-index: 100;
          width: 100%;
          background-color: rgba(255,255,255,.8);
          bottom: 5%;
          padding: rem-calc(10 0);
          h3  {
            font-weight: 700;
            font-family: $displayFont;
            line-height: 100%;
            text-transform: uppercase;
            color: $dark-gray;
            font-size: rem-calc(16);
            margin-bottom: rem-calc(2);
            margin-top: rem-calc(4);
            span {
              font-size: 80%;
            }
          }
          p {
            color: $dark-gray;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 100%;
            font-size: rem-calc(11);
            margin-bottom: rem-calc(4);
          }
        }
        img {
          width: 100%;
          -webkit-transition: all .5s ease-in-out;
          transition: all .5s ease-in-out;
          -moz-transition: all .5s ease-in-out;
          -webkit-transition: all .5s;
        }
        &:hover {
          img {
            @include breakpoint(medium) {
              transform: scale(1.05);
              -webkit-transform: scale(1.05);
            }
          }
        }
        .img-wrap {
          background-color: $white;
          transition: opacity .3s .25s ease-out;
          will-change: transform, opacity;
          position: relative;
          // margin: rem-calc(10);
            // position: relative;
            // overflow: hidden;
            // @include flex;
            // @include flex-align(center, middle);
            // border: 1px solid $medium-gray;

          .imageContainer {
            overflow: hidden;
            border: 1px solid $medium-gray;
          }
          .imgOverlay {
            filter: none;
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -webkit-transition: all .5s;
            &:hover, &:focus, &:active {
              opacity: 1;
            }
            position: absolute;
            z-index: 100;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.6);
            @include flex;
            @include flex-align(center, middle);
            opacity: 0;
            // @include breakpoint(medium) {
            //   z-index: 100;
            //   opacity: 0;
            //   position: absolute;
            //   background-color: rgba(0,0,0,0.6);
            //   width: 100%;
            //   height: 100%;
            //   @include flex;
            //   @include flex-align(center, middle);
            // }
            // @include breakpoint(small only) {
            //   @include xy-cell(12);
            // }
            .overlayContent {
              display: none;
              text-align: center;
              h3 {
                font-family: $displayFont;
                font-weight: 800;
                color: $primary-color;
                font-size: rem-calc(16);
                margin-bottom: rem-calc(0);
              }
              h4 {
                font-weight: 800;
                color: $medium-gray;
                font-size: rem-calc(15);
                margin-bottom: rem-calc(2);
              }
              p {
                color: $medium-gray;
                font-size: rem-calc(14);
              }
            }
          }
        }
      }
    }
  }
}
section.galleryPage {
  @include xy-grid;
  @include flex;
  @include flex-align(center);
  padding-top: rem-calc(80);
  padding-bottom: rem-calc(40);
  @include breakpoint(large) {
    padding-top: rem-calc(80);
  }
  .galleryContainer {
    @include xy-cell(12);
    @include flex;
    @include flex-align(center);
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    @include breakpoint(large) {
      @include xy-cell(11);
    }
    .myIsGallery {
      margin: 0;
      padding-bottom: rem-calc(8);
      @include xy-cell(12);
      @include breakpoint(large) {
        @include xy-cell(11);
      }
      .pageTitle {
        p {
          text-align: center;
          text-transform: uppercase;
          font-family: $bodyFont;
          font-weight: 700;
          font-size: rem-calc(9);
          margin-bottom: rem-calc(4);
        }
        h4  {
          text-align: center;
          text-transform: uppercase;
          font-family: $displayFont;
          font-weight: 800;
          font-size: rem-calc(12);
          color: $primary-color;
          line-height: 90%;
          margin-bottom: rem-calc(4);
        }
      }
      a.isGallery-large {
        width: 100%;
        padding: rem-calc(1 3);
        @include breakpoint(medium) {
  	  	width: 50%;
  	  	}
  	  	@include breakpoint(large) {
  	  	width: 50%;
  	  	}
        @include breakpoint(xxlarge) {
          width: 33.33%;
        }
        img {
          width: 100%;
          -webkit-transition: all .5s ease-in-out;
          transition: all .5s ease-in-out;
          -moz-transition: all .5s ease-in-out;
          -webkit-transition: all .5s;
        }
        &:hover {
          img {
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
          }
        }
        //uncomment the following fo show on scroll
        .img-wrap.is-visible {
          opacity: 1;
        }
        .img-wrap {
          position: relative;
          overflow: hidden;
          background-color: $white;
          @include flex;
          @include flex-align(center, middle);
          //change opacity to 0 if using show on scroll
          opacity: 0;
          transition: opacity .3s .25s ease-out;
          will-change: transform, opacity;
          .imgOverlay {
            opacity: 0;
            z-index: 100;
            @include flex;
            @include flex-align(center, middle);
            position:absolute;
            background-color: rgba(0,0,0,0.6);
            width: 100%;
            height: 100%;
            filter: none;
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -webkit-transition: all .5s;
            &:hover, &:focus, &:active {
              opacity: 1;
            }
            .overlayContent {
              display: block;
              text-align: center;
              h3  {
                font-family: $displayFont;
                text-transform: uppercase;
                font-weight: 700;
                color: $primary-color;
                font-size: rem-calc(16);
                margin-bottom: rem-calc(0);
              }
              // h3:before, h3:after {
              //   content: "\"";
              // }
              h4 {
                @include font-bold;
                color: $medium-gray;
                font-size: rem-calc(13);
                margin-bottom: rem-calc(4);
              }
              p {
                color: $medium-gray;
                font-size: rem-calc(12);
              }
            }
          }
        }
      }
    }
  }
}
section.about {
  @include xy-grid();
  padding-top: rem-calc(80);
  padding-bottom: rem-calc(40);
  div.container {
    @include flex;
    @include flex-align(center);
  }
  div.aboutContent {
    @include xy-cell(12);
    margin-top: rem-calc(30);
    color: $almost-black;
    @include breakpoint(large) {
      @include xy-cell(10);
    }
    p {
      font-size: rem-calc(15);
    }
    p.prints {
      margin-top: rem-calc(40);
    }
    @include breakpoint(large) {
      padding: rem-calc(0 0 0 20);
      border-left: 1px solid $medium-gray;
    }
  }
  .myFormContainer {
    @include xy-grid();
    label {
      color: $light-gray;
      text-transform: uppercase;
    }
    .myNameField, .myEmailField {
      @include xy-cell(12);
      @include breakpoint(medium) {
        @include xy-cell(6);
      }
    }
    .myMessageField {
      @include xy-cell(12);
    }
  }
}
section.contact {
  padding-top: rem-calc(40);
  .myFormContainer {
    @include xy-grid();
    label {
      color: $light-gray;
      text-transform: uppercase;
    }
    .myNameField, .myEmailField {
      @include xy-cell(6);
    }
    .myMessageField {
      @include xy-cell(12);
    }
  }
}
